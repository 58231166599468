var render = function render(){
  var _vm$validationResults, _vm$validationResults2, _vm$validationResults3, _vm$invoice$account$e;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [((_vm$validationResults = _vm.validationResults) === null || _vm$validationResults === void 0 ? void 0 : (_vm$validationResults2 = _vm$validationResults.results) === null || _vm$validationResults2 === void 0 ? void 0 : (_vm$validationResults3 = _vm$validationResults2.summary) === null || _vm$validationResults3 === void 0 ? void 0 : _vm$validationResults3.length) > 0 ? _c('div', [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v(" Validation "), _vm.validationResults.results.messages && _vm.validationResults.results.messages.length > 0 ? _c('span', {
    staticClass: "font-size-lg"
  }, [_c('span', {
    staticClass: "badge badge-warning mr-2"
  }, [_vm._v(_vm._s(_vm.validationResults.results.messages.filter(function (result) {
    return result.type === 'warning';
  }).length) + " issues")]), _c('span', {
    staticClass: "badge badge-danger"
  }, [_vm._v(_vm._s(_vm.validationResults.results.messages.filter(function (result) {
    return result.type === 'danger';
  }).length) + " warnings")])]) : _vm._e()]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" This invoice has been validated against a set of rules. Checks are made against related invoices, accounts and contracts. Anything that may be an issue or problem will be highlighted below. For example, if the invoice dates overlap with another invoice on the same account. ")]), _vm._m(0), _vm._l(_vm.validationResults.results.summary, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "alert",
      class: `alert-${item.type}`,
      attrs: {
        "role": "alert"
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(item.message) + " "), item.link ? _c('router-link', {
      staticClass: "font-w600",
      attrs: {
        "to": item.link
      }
    }, [_vm._v("Details")]) : _vm._e()], 1)])]);
  })], 2) : _vm._e(), _vm.validationResults.results.values.length > 0 ? _c('div', [_c('p', [_c('strong', [_vm._v("Entity Name: ")]), _vm._v(" " + _vm._s(_vm.invoice.account ? (_vm$invoice$account$e = _vm.invoice.account.entity) === null || _vm$invoice$account$e === void 0 ? void 0 : _vm$invoice$account$e.legalName : 'N/A'))]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Invoice Details")]), _c('p', [_vm._v("Any non-financial details related to the invoice are shown below, such as the invoice number, start/end dates and meter information.")]), _c('table', {
    staticClass: "table table-striped mb-4"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.validationResultsMetadata, function (value) {
    return _c('tr', {
      key: value.key
    }, [_c('td', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(value.name))]), _c('td', [_vm._v(" " + _vm._s(value.friendlyValue)), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(value.friendlyCalculatedValue))])]), _c('td', {
      staticClass: "text-info font-w600"
    }, [_c('FormItem', {
      attrs: {
        "id": value.key,
        "type": value.formType,
        "options": [],
        "value": value.formType === 'datePicker' ? value.calculatedValue : value.friendlyCalculatedValue
      },
      on: {
        "input": function (input) {
          return _vm.onInputValue(input, value.key);
        }
      }
    })], 1), _c('td', {
      staticClass: "tfont-w600"
    }, _vm._l(value.validation, function (item, index) {
      return _c('span', {
        key: index,
        class: `text-${item.type}`
      }, [_vm._v(" " + _vm._s(item.message) + " ")]);
    }), 0)]);
  }), 0)]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-3"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("Rates")]), _c('span', [_c('button', {
    staticClass: "btn btn-alt-info mr-3",
    on: {
      "click": _vm.onClickRevalidate
    }
  }, [_vm.loading.revalidateInvoice[_vm.invoice._id] ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-spin fa-redo"
  }), _vm._v(" Refreshing Contract Rates...")]) : _vm._e(), !_vm.loading.revalidateInvoice[_vm.invoice._id] ? _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-redo"
  }), _vm._v(" Refresh Contract Rates")]) : _vm._e()]), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onClickAddRate
    }
  }, [_vm._v("Add Rate")])])]), _c('p', [_vm._v("All the rates related to this invoice. Changing the consumption or cost of a rate will automatically update the totals table below.")]), _c('strong', [_vm._v("Note about contracts")]), _c('p', [_vm._v(" Unit rates will be validated against their respective contract rate (if a contract exists for this invoice's account). If this is the first invoice uploaded for an account, you will need to map each unit rate to their respective contract rates. Contracts and contract rates can be managed from the Account Management area (Select an Asset -> Accounts tab -> Contracts). ")]), _vm._m(2), _c('table', {
    staticClass: "table mb-4"
  }, _vm._l(_vm.rates, function (rate, index) {
    var _vm$invoice$account, _vm$invoice$account2, _vm$invoice$account3, _vm$invoice$account4, _vm$invoice$account5, _vm$invoice$account6;
    return _c('tbody', {
      key: rate.id,
      attrs: {
        "id": `rate-${rate.id}`
      }
    }, [_c('tr', [_c('th', {
      staticClass: "bg-light",
      attrs: {
        "colspan": "4"
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('span', {
      staticClass: "font-size-h5"
    }, [_vm._v(_vm._s(rate.rateName))]), _c('span', [['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) && rate.inputs.startRead === null && rate.inputs.startRead === null ? _c('button', {
      staticClass: "btn btn-primary btn-sm mr-3",
      on: {
        "click": function ($event) {
          return _vm.onClickAddReads(rate);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-tachometer-alt mr-1"
    }), _vm._v(" Add Reads ")]) : _vm._e(), rate.inputs.startRead !== null || rate.inputs.startRead !== null ? _c('button', {
      staticClass: "btn btn-alt-danger btn-sm mr-3",
      on: {
        "click": function ($event) {
          return _vm.onClickRemoveReads(rate);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-tachometer-alt mr-1"
    }), _vm._v(" Remove Reads ")]) : _vm._e(), rate.inputs.startDate === null && rate.inputs.startDate === null ? _c('button', {
      staticClass: "btn btn-primary btn-sm mr-3",
      on: {
        "click": function ($event) {
          return _vm.onClickAddDates(rate);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-calendar-alt mr-1"
    }), _vm._v(" Add Dates ")]) : _vm._e(), rate.inputs.startDate !== null || rate.inputs.startDate !== null ? _c('button', {
      staticClass: "btn btn-alt-danger btn-sm mr-3",
      on: {
        "click": function ($event) {
          return _vm.onClickRemoveDates(rate);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-calendar-alt mr-1"
    }), _vm._v(" Remove Dates ")]) : _vm._e(), _c('button', {
      staticClass: "btn btn-alt-danger btn-sm",
      class: {
        disabled: _vm.loading.removeInvoiceRate[_vm.invoice._id] === index
      },
      on: {
        "click": function ($event) {
          return _vm.onClickRemoveRate(rate, index);
        }
      }
    }, [_vm.loading.removeInvoiceRate[_vm.invoice._id] === index ? _c('span', [_c('i', {
      staticClass: "fa fa-spinner fa-spin mr-1"
    }), _vm._v(" Removing")]) : _c('span', [_c('i', {
      staticClass: "fa fa-ban mr-1"
    }), _vm._v(" Remove")])])])])])]), _c('tr', [_vm._m(3, true), _c('td', [_vm._v(_vm._s(_vm.getFriendlyRateType(rate.type)))]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `consumption-${rate.id}`,
        "type": "select",
        "value": rate.inputs.type,
        "options": _vm.rateTypes
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'type');
        }
      }
    })], 1)]), rate.inputs.startDate !== null || rate.inputs.endDate !== null ? _c('tr', [_vm._m(4, true), _c('td', [_vm._v(" " + _vm._s(rate.friendlyStartDate)), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(rate.friendlyCalculatedStartDate))])]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `startDate-${rate.id}`,
        "type": "datePicker",
        "value": rate.inputs.startDate
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'startDate');
        }
      }
    })], 1), _c('td', {
      staticClass: "font-w600"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.rateValidationHtml(rate, 'startDate'))
      }
    })])]) : _vm._e(), rate.inputs.startDate !== null || rate.inputs.endDate !== null ? _c('tr', [_vm._m(5, true), _c('td', [_vm._v(" " + _vm._s(rate.friendlyEndDate)), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(rate.friendlyCalculatedEndDate))])]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `endDate-${rate.id}`,
        "type": "datePicker",
        "value": rate.inputs.endDate
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'endDate');
        }
      }
    })], 1), _c('td', {
      staticClass: "font-w600"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.rateValidationHtml(rate, 'endDate'))
      }
    })])]) : _vm._e(), rate.type !== 'tax' ? _c('tr', [_vm._m(6, true), _c('td', [_vm._v(" " + _vm._s(rate.friendlyConsumption)), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(rate.friendlyCalculatedConsumption))])]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `consumption-${rate.id}`,
        "type": "number",
        "step": "0.01",
        "value": rate.inputs.consumption
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'consumption');
        }
      }
    })], 1), _c('td', {
      staticClass: "font-w600"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.rateValidationHtml(rate, 'consumption'))
      }
    })])]) : _vm._e(), rate.unitRate !== null ? _c('tr', [_vm._m(7, true), _c('td', [_vm._v(" " + _vm._s(rate.friendlyUnitRate)), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(rate.friendlyCalculatedUnitRate))])]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `unitRate-${rate.id}`,
        "type": "number",
        "step": "0.01",
        "value": rate.inputs.unitRate
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'unitRate');
        }
      }
    })], 1), _c('td', [_c('span', {
      staticClass: "font-w600",
      domProps: {
        "innerHTML": _vm._s(_vm.rateValidationHtml(rate, 'unitRate'))
      }
    }), (rate.validation || []).some(function (v) {
      return v.code === 'NO_CONTRACT_MAPPING';
    }) ? _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": `contractRateType-${rate.id}`,
        "label": "Contract Rates",
        "type": "select",
        "options": _vm.validationResults.results.contract.rates.map(function (r) {
          return {
            label: r.type,
            value: r.type
          };
        })
      },
      on: {
        "input": function (input) {
          return _vm.onSelectContractRateType(input, rate);
        }
      }
    }), _c('div', {
      staticClass: "input-group-append"
    }, [_c('button', {
      staticClass: "btn btn-primary",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.onClickSubmitContractRateType(rate);
        }
      }
    }, [_vm._v("Submit")])])], 1) : _vm._e()])]) : _vm._e(), _c('tr', [_vm._m(8, true), _c('td', [_vm._v(" " + _vm._s(rate.cost)), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(rate.calculatedCost))])]), _c('td', [_c('FormItem', {
      attrs: {
        "id": `cost-${rate.id}`,
        "type": "number",
        "step": "0.01",
        "value": rate.inputs.cost
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'cost');
        }
      }
    })], 1), _c('td', [_c('span', {
      staticClass: "font-w600",
      domProps: {
        "innerHTML": _vm._s(_vm.rateValidationHtml(rate, 'cost'))
      }
    })])]), rate.inputs.startRead !== null || rate.inputs.endRead !== null ? _c('tr', [_c('td', {
      style: {
        'padding-top': '33px'
      }
    }, [_c('strong', [_vm._v("Start Read")])]), _c('td', {
      style: {
        'padding-top': '33px'
      }
    }, [_vm._v(" " + _vm._s(rate.friendlyStartRead) + " "), rate.friendlyStartReadType ? _c('span', [_vm._v("(" + _vm._s(rate.friendlyStartReadType) + ")")]) : _vm._e(), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(" " + _vm._s(rate.friendlyCalculatedStartRead) + " "), rate.friendlyCalculatedStartReadType ? _c('span', [_vm._v("(" + _vm._s(rate.friendlyCalculatedStartReadType) + ")")]) : _vm._e()])]), _c('td', [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      class: _vm.displayRegister((_vm$invoice$account = _vm.invoice.account) === null || _vm$invoice$account === void 0 ? void 0 : _vm$invoice$account.registerIds) ? 'col-md-6' : 'col-md-8'
    }, [_c('div', {
      staticClass: "form-group mb-0"
    }, [_c('label', [_vm._v("Value")]), _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": `startRead-${rate.id}`,
        "type": "number",
        "min": 0,
        "step": "0.01",
        "placeholder": "Value",
        "value": rate.inputs.startRead
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'startRead');
        }
      }
    }), _c('div', {
      staticClass: "input-group-append w-50"
    }, [_c('FormItem', {
      style: {
        width: '100%'
      },
      attrs: {
        "id": `startReadUnits-${rate.id}`,
        "label": "Start Read Unit",
        "options": _vm.unitOptions,
        "type": "select",
        "is-alt": true
      },
      model: {
        value: _vm.form.startReadUnit,
        callback: function ($$v) {
          _vm.$set(_vm.form, "startReadUnit", $$v);
        },
        expression: "form.startReadUnit"
      }
    })], 1)], 1)])]), _c('div', {
      class: _vm.displayRegister((_vm$invoice$account2 = _vm.invoice.account) === null || _vm$invoice$account2 === void 0 ? void 0 : _vm$invoice$account2.registerIds) ? 'col-md-3' : 'col-md-4'
    }, [_c('label', [_vm._v(" Type ")]), _c('FormItem', {
      attrs: {
        "id": `startReadType-${rate.id}`,
        "type": "text",
        "placeholder": "Type",
        "value": rate.inputs.startReadType
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'startReadType');
        }
      }
    })], 1), _vm.displayRegister((_vm$invoice$account3 = _vm.invoice.account) === null || _vm$invoice$account3 === void 0 ? void 0 : _vm$invoice$account3.registerIds) ? _c('div', {
      staticClass: "col-md-3"
    }, [_c('label', [_vm._v(" Register ")]), _c('FormItem', {
      attrs: {
        "id": `startReadRegister-${rate.id}`,
        "value": rate.inputs.startReadRegister,
        "type": "select",
        "placeholder": "Register",
        "options": _vm.filteredRegisterIds(_vm.invoice.account.registerIds)
      },
      on: {
        "input": function (val) {
          return _vm.onInputRate(val, rate.id, 'startReadRegister');
        }
      }
    })], 1) : _vm._e()])]), _c('td', {
      style: {
        'padding-top': '33px'
      }
    }, [_c('span', {
      staticClass: "font-w600",
      domProps: {
        "innerHTML": _vm._s(_vm.rateValidationHtml(rate, 'startRead'))
      }
    })])]) : _vm._e(), rate.inputs.startRead !== null || rate.inputs.endRead !== null ? _c('tr', [_c('td', {
      style: {
        'padding-top': '33px'
      }
    }, [_c('strong', [_vm._v("End Read")])]), _c('td', {
      style: {
        'padding-top': '33px'
      }
    }, [_vm._v(" " + _vm._s(rate.friendlyEndRead) + " "), rate.friendlyEndReadType ? _c('span', [_vm._v("(" + _vm._s(rate.friendlyEndReadType) + ")")]) : _vm._e(), _c('br'), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(" " + _vm._s(rate.friendlyCalculatedEndRead) + " "), rate.friendlyCalculatedEndReadType ? _c('span', [_vm._v("(" + _vm._s(rate.friendlyCalculatedEndReadType) + ")")]) : _vm._e()])]), _c('td', [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      class: _vm.displayRegister((_vm$invoice$account4 = _vm.invoice.account) === null || _vm$invoice$account4 === void 0 ? void 0 : _vm$invoice$account4.registerIds) ? 'col-md-6' : 'col-md-8'
    }, [_c('div', {
      staticClass: "form-group mb-0"
    }, [_c('label', [_vm._v("Value")]), _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": `endRead-${rate.id}`,
        "type": "number",
        "min": 0,
        "step": "0.01",
        "placeholder": "Value",
        "value": rate.inputs.endRead
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'endRead');
        }
      }
    }), _c('div', {
      staticClass: "input-group-append w-50"
    }, [_c('FormItem', {
      style: {
        width: '100%'
      },
      attrs: {
        "id": `endReadUnits-${rate.id}`,
        "label": "End Read Unit",
        "options": _vm.unitOptions,
        "type": "select",
        "is-alt": true
      },
      model: {
        value: _vm.form.endReadUnit,
        callback: function ($$v) {
          _vm.$set(_vm.form, "endReadUnit", $$v);
        },
        expression: "form.endReadUnit"
      }
    })], 1)], 1)])]), _c('div', {
      class: _vm.displayRegister((_vm$invoice$account5 = _vm.invoice.account) === null || _vm$invoice$account5 === void 0 ? void 0 : _vm$invoice$account5.registerIds) ? 'col-md-3' : 'col-md-4'
    }, [_c('label', [_vm._v(" Type ")]), _c('FormItem', {
      attrs: {
        "id": `endReadType-${rate.id}`,
        "type": "text",
        "placeholder": "Type",
        "value": rate.inputs.endReadType
      },
      on: {
        "input": function (input) {
          return _vm.onInputRate(input, rate.id, 'endReadType');
        }
      }
    })], 1), _vm.displayRegister((_vm$invoice$account6 = _vm.invoice.account) === null || _vm$invoice$account6 === void 0 ? void 0 : _vm$invoice$account6.registerIds) ? _c('div', {
      staticClass: "col-md-3"
    }, [_c('label', [_vm._v(" Register ")]), _c('FormItem', {
      attrs: {
        "id": `endReadRegister-${rate.id}`,
        "value": rate.inputs.endReadRegister,
        "type": "select",
        "placeholder": "Register",
        "options": _vm.filteredRegisterIds(_vm.invoice.account.registerIds)
      },
      on: {
        "input": function (val) {
          return _vm.onInputRate(val, rate.id, 'endReadRegister');
        }
      }
    })], 1) : _vm._e()])]), _c('td', {
      style: {
        'padding-top': '33px'
      }
    }, [_c('span', {
      staticClass: "font-w600",
      domProps: {
        "innerHTML": _vm._s(_vm.rateValidationHtml(rate, 'endRead'))
      }
    })])]) : _vm._e()]);
  }), 0), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Totals")]), _vm._m(9), _vm.recalculations.length > 0 ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v(" Some totals have been re-calculated as changes were made to one or more rates. ")]) : _vm._e(), _c('table', {
    staticClass: "table table-striped mb-4"
  }, [_vm._m(10), _c('tbody', _vm._l(_vm.validationResultsTotals, function (value) {
    return _c('tr', {
      key: value.key
    }, [_c('td', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(value.name))]), _c('td', [value.friendlyValue ? _c('div', [_vm._v(_vm._s(value.friendlyValue))]) : _vm._e(), value.friendlyCalculatedValue ? _c('div', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(value.friendlyCalculatedValue))]) : _vm._e()]), _c('td', {
      staticClass: "text-info font-w600"
    }, [value.value !== null ? _c('FormItem', {
      class: {
        'is-valid': _vm.recalculations.filter(function (message) {
          return message.key === value.key;
        }).length
      },
      attrs: {
        "id": value.key,
        "type": value.formType,
        "options": [],
        "value": _vm.form[value.key]
      },
      on: {
        "input": function (input) {
          return _vm.onInputValue(input, value.key);
        }
      }
    }) : value.type === 'select' ? _c('FormItem', {
      class: {
        'is-valid': _vm.recalculations.filter(function (message) {
          return message.key === value.key;
        }).length
      },
      attrs: {
        "id": value.key,
        "options": [],
        "type": value.formType,
        "value": _vm.form[value.key]
      },
      on: {
        "input": function (input) {
          return _vm.onInputValue(input, value.key);
        }
      }
    }) : _c('FormItem', {
      class: {
        'is-valid': _vm.recalculations.filter(function (message) {
          return message.key === value.key;
        }).length
      },
      attrs: {
        "id": value.key,
        "options": [],
        "type": value.formType,
        "value": _vm.form[value.key]
      },
      on: {
        "input": function (input) {
          return _vm.onInputValue(input, value.key);
        }
      }
    }), _vm._l(_vm.recalculations.filter(function (message) {
      return message.key === value.key;
    }), function (item, index) {
      return _c('div', {
        key: index,
        staticClass: "valid-feedback"
      }, [_vm._v(" [Updated] " + _vm._s(item.message) + " ")]);
    })], 2), _c('td', {
      staticClass: "font-w600"
    }, [_c('ul', {
      staticClass: "list-unstyled"
    }, _vm._l(value.validation, function (item, index) {
      return _c('li', {
        key: index,
        class: `text-${item.type}`
      }, [_vm._v(" " + _vm._s(item.message) + " ")]);
    }), 0)])]);
  }), 0)]), _c('button', {
    staticClass: "btn btn-primary btn-block",
    class: {
      disabled: _vm.loading.confirmInvoice[_vm.invoice._id]
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickSubmit.apply(null, arguments);
      }
    }
  }, [!_vm.loading.confirmInvoice[_vm.invoice._id] ? _c('span', [_vm._v("Confirm Invoice")]) : _c('span', [_c('i', {
    staticClass: "fa fa-spinner fa-spin"
  }), _vm._v(" Confirming Invoice...")])])]) : _vm._e(), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.addRate,
      "title": "Add Rate",
      "text": "Please enter/select a rate name",
      "lg-size": ""
    },
    on: {
      "close": function ($event) {
        _vm.modals.addRate = false;
      },
      "submit": _vm.onConfirmRateModal
    }
  }, [_c('AddRateForm', {
    attrs: {
      "register-ids": _vm.filteredRegisterIds(_vm.invoice.account.registerIds),
      "unit-options": _vm.unitOptions
    },
    model: {
      value: _vm.modals.addRate,
      callback: function ($$v) {
        _vm.$set(_vm.modals, "addRate", $$v);
      },
      expression: "modals.addRate"
    }
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Validation results for the invoice as a whole are shown directly below. Any validation results for a specific fields will be shown beside the respective field. For example, if an invoice end reading does not follow on from a previous invoice's start read, this will show beside the "), _c('strong', [_vm._v("End Read")]), _vm._v(" field. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Field")]), _c('th', [_vm._v("Invoice/Calculated Value")]), _c('th', [_vm._v("Saved Value")]), _c('th', [_vm._v("Validation")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" If you have made a change to a contract, use the blue "), _c('strong', [_vm._v("Re-validate")]), _vm._v(" button above to check your changes against this invoice. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Type")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Start Date")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("End Date")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Consumption")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Unit Rate")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Cost")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" The below table are all the totals related to this invoice. "), _c('strong', [_vm._v("Editing a total will not automatically update any related totals (e.g. updating \"Total Tax\" will not update \"Total Cost\"). These values will be used for reporting, so please ensure they are all correct.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Field")]), _c('th', [_vm._v("Current Value")]), _c('th', [_vm._v("Confirmed Value")]), _c('th', [_vm._v("Validation")])])]);

}]

export { render, staticRenderFns }