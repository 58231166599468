var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeader', {
    attrs: {
      "title": "Invoice Upload",
      "sub-title": "Upload invoices and preview the results"
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('InvoiceUpload'), _c('h3', {
    staticClass: "mb-1"
  }, [_vm._v("Unconfirmed Invoices")]), _c('p', [_vm._v("Invoices that have been processed, but require manual confirmation.")]), _c('InvoiceValidation')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }