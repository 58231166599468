var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-5",
    attrs: {
      "data-cy": "invoices-validation"
    }
  }, [_c('div', {
    staticClass: "row items-push justify-content-between"
  }, [_c('div', {
    staticClass: "col-sm-6 col-xl-6"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.search,
      expression: "filters.search"
    }],
    staticClass: "form-control border-left-0 m-0",
    style: {
      height: 'auto'
    },
    attrs: {
      "id": "invoices-search",
      "type": "text",
      "name": "invoices-search",
      "placeholder": "Search Invoices..."
    },
    domProps: {
      "value": _vm.filters.search
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters, "search", $event.target.value);
      }, _vm.onSearch]
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.searchBy,
      expression: "filters.searchBy"
    }],
    staticClass: "form-control form-control-alt",
    attrs: {
      "id": "invoices-search-by",
      "name": "invoices-search-by"
    },
    on: {
      "input": _vm.onSearch,
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.filters, "searchBy", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "fileName"
    }
  }, [_vm._v("Invoice File Name")]), _c('option', {
    attrs: {
      "value": "values.meterPointNumber"
    }
  }, [_vm._v("MPRN/MPN/SPID")]), _c('option', {
    attrs: {
      "value": "values.meterSerialNumber"
    }
  }, [_vm._v("Meter Serial Number")]), _c('option', {
    attrs: {
      "value": "entity"
    }
  }, [_vm._v("Entity Name")]), _c('option', {
    attrs: {
      "value": "asset"
    }
  }, [_vm._v("Asset Name")]), _c('option', {
    attrs: {
      "value": "tags"
    }
  }, [_vm._v("Invoice Tag")])])])])]), _c('div', {
    staticClass: "col-sm-4 col-xl-4 d-flex align-items-center"
  }, [_vm._m(1), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.sort,
      expression: "filters.sort"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "invoices-sort",
      "name": "invoices-sort"
    },
    on: {
      "input": _vm.onSearch,
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.filters, "sort", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "createdAt:1"
    }
  }, [_vm._v("Capture Date (Asc)")]), _c('option', {
    attrs: {
      "value": "createdAt:-1"
    }
  }, [_vm._v("Capture Date (Desc)")]), _c('option', {
    attrs: {
      "value": "uploaderStatus:1"
    }
  }, [_vm._v("Uploader Status (Asc)")]), _c('option', {
    attrs: {
      "value": "uploaderStatus:-1"
    }
  }, [_vm._v("Uploader Status (Desc)")]), _c('option', {
    attrs: {
      "value": "values.invoiceDate:1"
    }
  }, [_vm._v("Invoice Date (Asc)")]), _c('option', {
    attrs: {
      "value": "values.invoiceDate:-1"
    }
  }, [_vm._v("Invoice Date (Desc)")]), _c('option', {
    attrs: {
      "value": "userSub:1"
    }
  }, [_vm._v("User (Asc)")]), _c('option', {
    attrs: {
      "value": "userSub:-1"
    }
  }, [_vm._v("User (Desc)")]), _c('option', {
    attrs: {
      "value": "fileName:1"
    }
  }, [_vm._v("File Name (Asc)")]), _c('option', {
    attrs: {
      "value": "fileName:-1"
    }
  }, [_vm._v("File Name (Desc)")])])])]), _c('div', {
    staticClass: "row align-items-center bg-lighter pb-4 pt-3 px-3 rounded-md mb-3"
  }, [_c('div', {
    staticClass: "col-md-1 pr-0"
  }, [_c('label', {
    staticClass: "mb-2"
  }, [_vm._v("Select All")]), _c('div', {
    staticClass: "custom-control custom-switch custom-control-lg"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "id": "select-all-invoices",
      "name": "select-all-invoices",
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.selectedInvoices.length !== 0 && _vm.selectedInvoices.length === _vm.results.length
    },
    on: {
      "change": _vm.onSelectAllInvoices
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "select-all-invoices"
    }
  })])]), _c('div', {
    staticClass: "col-md-1"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "uploaderStatus",
      "label": "Status",
      "type": "select",
      "options": [{
        label: 'All',
        value: 'all'
      }, {
        label: 'Active',
        value: 'active'
      }, {
        label: 'On Hold',
        value: 'onhold'
      }]
    },
    on: {
      "input": _vm.onSearch
    },
    model: {
      value: _vm.filters.uploaderStatus,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "uploaderStatus", $$v);
      },
      expression: "filters.uploaderStatus"
    }
  })], 1), _c('div', {
    staticClass: "col-md-2"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "uploadedBy",
      "label": "Uploaded By",
      "type": "select",
      "options": _vm.uploadUsers
    },
    on: {
      "input": _vm.onSearch
    },
    model: {
      value: _vm.filters.userSub,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "userSub", $$v);
      },
      expression: "filters.userSub"
    }
  })], 1), _c('div', {
    staticClass: "col-md-2"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "assignedTo",
      "label": "Assigned To",
      "type": "select",
      "options": _vm.assignedUsers
    },
    on: {
      "input": _vm.onSearch
    },
    model: {
      value: _vm.filters.assignedTo,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "assignedTo", $$v);
      },
      expression: "filters.assignedTo"
    }
  })], 1), _c('div', {
    staticClass: "col-md-2"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "supplier",
      "label": "Suppliers",
      "type": "select",
      "options": _vm.sortedSuppliers
    },
    on: {
      "input": _vm.onSearch
    },
    model: {
      value: _vm.filters.supplierId,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "supplierId", $$v);
      },
      expression: "filters.supplierId"
    }
  })], 1), _c('div', {
    staticClass: "col font-w600 text-right"
  }, [_vm.invoicesTotal.filteredTotal !== _vm.invoicesTotal.total ? _c('span', [_vm._v(" Showing " + _vm._s(_vm.results.length) + " results out of " + _vm._s(_vm.invoicesTotal.filteredTotal) + " filtered results (" + _vm._s(_vm.invoicesTotal.total) + " total)")]) : _c('span', [_vm._v("Showing " + _vm._s(_vm.results.length) + " results out of " + _vm._s(_vm.invoicesTotal.filteredTotal) + " total results")])])]), _vm.selectedInvoices.length > 0 ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "custom-control custom-switch custom-control-lg"
  }, [_c('button', {
    staticClass: "btn btn-alt-danger mr-3",
    class: {
      disabled: Object.keys(_vm.loading.deleteInvoices).some(function (key) {
        return _vm.loading.deleteInvoices[key];
      })
    },
    on: {
      "click": function ($event) {
        _vm.modals.delete.open = true;
      }
    }
  }, [Object.keys(_vm.loading.deleteInvoices).some(function (key) {
    return _vm.loading.deleteInvoices[key];
  }) ? _c('span', [_vm._v(" Deleting... ")]) : _c('span', {
    attrs: {
      "data-cy": "delete-selected-btn"
    }
  }, [_vm._v(" Delete Selected ")])])]), _c('div', {
    staticClass: "custom-control custom-switch custom-control-lg mr-5"
  }, [_c('button', {
    staticClass: "btn btn-alt-warning",
    class: {
      disabled: _vm.loading.update
    },
    on: {
      "click": _vm.onAssign
    }
  }, [_c('span', {
    attrs: {
      "data-cy": "assign-selected-btn"
    }
  }, [_vm._v(" Assign ")])])])])]) : _vm._e(), _vm.newInvoices > 0 ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" " + _vm._s(_vm.newInvoices) + " new invoice" + _vm._s(_vm.newInvoices > 1 ? 's have' : ' has') + " been uploaded. "), _c('a', {
    staticClass: "font-w600",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.refresh.apply(null, arguments);
      }
    }
  }, [_vm._v("Click here to refresh")])]) : _vm._e(), _vm.results.length > 0 ? _c('div', [_vm._l(_vm.results, function (result) {
    var _result$invoice$accou;
    return _c('div', {
      key: result._id,
      staticClass: "InvoiceValidation-item"
    }, [_c('div', {
      staticClass: "block-content block-content-full ribbon ribbon-danger ribbon-left ribbon-modern",
      class: {
        'border-bottom': result.validation.show
      }
    }, [result.invoice.uploaderStatus === 'onhold' ? _c('div', {
      staticClass: "ribbon-box ribbon-box-sm text-uppercase"
    }, [_vm._v("On Hold")]) : _vm._e(), _c('div', {
      staticClass: "row justify-content-between align-items-center"
    }, [_c('div', {
      staticClass: "col-lg mb-4 mb-xl-0"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-xl-6 d-flex align-items-center mb-4 mb-xl-0"
    }, [_c('div', {
      staticClass: "custom-control custom-checkbox custom-control-lg ml-3 mr-4"
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "id": result._id,
        "type": "checkbox",
        "name": result._id
      },
      domProps: {
        "checked": _vm.selectedInvoices.some(function (id) {
          return id === result._id;
        })
      },
      on: {
        "change": function ($event) {
          return _vm.onSelectInvoice(result.invoice);
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": result._id
      }
    })]), !_vm.loading.updateInvoice[result._id] && !_vm.loading.getInvoice[result._id] ? _c('div', {
      staticClass: "ml-3"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickShowInvoice(result);
        }
      }
    }, [_c('div', {
      staticClass: "mr-2"
    }, [_c('strong', [_c('InvoiceName', {
      attrs: {
        "invoice": result.invoice
      }
    })], 1)]), _c('small', [_vm._v("Uploaded by "), _c('strong', [_vm._v(_vm._s(_vm.getUserFromSub(result.invoice.userSub)))]), _vm._v(" at "), _c('strong', [_vm._v(_vm._s(_vm._f("date")(result.invoice.createdAt, 'Do MMM YYYY HH:mm:ss')))])]), _c('div', [result.invoice.supplier && result.invoice.supplier.name ? _c('span', {
      staticClass: "badge badge-success text-capitalize mr-2"
    }, [_vm._v(_vm._s(result.invoice.supplier.name))]) : _vm._e(), result.invoice.type ? _c('span', {
      staticClass: "badge badge-info text-capitalize mr-2"
    }, [_vm._v(_vm._s(result.invoice.type))]) : _vm._e(), result.invoice.accountId ? _c('span', {
      staticClass: "badge badge-warning mr-2"
    }, [_vm._v("Account Match")]) : _vm._e(), result.invoice.templateVersion ? _c('span', {
      staticClass: "badge badge-secondary mr-2"
    }, [_vm._v(_vm._s(result.invoice.templateVersion))]) : _vm._e(), result.validation.hasAccount && result.validation.results.messages && result.validation.results.messages.length > 0 && result.validation.results.messages.every(function (r) {
      return r.type === 'success';
    }) ? _c('span', {
      staticClass: "mr-2"
    }, [_c('i', {
      staticClass: "fa fa-check-circle text-success"
    })]) : _vm._e(), _vm._l(result.invoice.tags, function (tag) {
      return _c('span', {
        key: tag,
        staticClass: "badge badge-primary text-capitalize mr-2",
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            $event.preventDefault();
            return _vm.onClickTagFilter(tag);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-fw fa-tag"
      }), _vm._v(" " + _vm._s(tag))]);
    }), result.invoice.assignedTo ? _c('span', {
      staticClass: "badge badge-primary text-capitalize mr-2"
    }, [_c('i', {
      staticClass: "fa fa-fw fa-user"
    }), _vm._v(" " + _vm._s(_vm.getUserFromSub(result.invoice.assignedTo)))]) : _vm._e()], 2)])]) : _c('span', {
      staticClass: "font-w600 text-secondary mr-2 my-4"
    }, [_c('i', {
      staticClass: "fa fa-spinner fa-spin mr-1"
    }), _vm._v(" Loading... ")])]), !_vm.loading.updateInvoice[result._id] && !_vm.loading.getInvoice[result._id] ? _c('div', {
      staticClass: "col-xl-6 d-flex justify-content-xl-end align-items-center"
    }, [_vm.loading.deleteInvoices[result._id] ? _c('div', {
      staticClass: "font-w600 text-danger mr-2"
    }, [_c('i', {
      staticClass: "fa fa-spinner fa-spin mr-1"
    }), _vm._v(" Deleting... ")]) : _c('div', [_vm.$auth.isAdmin ? _c('button', {
      staticClass: "btn btn-link font-w600 text-info",
      attrs: {
        "disabled": _vm.loading.recaptureInvoice[result._id]
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickRecapture(result.invoice);
          }.apply(null, arguments);
        }
      }
    }, [_vm.loading.recaptureInvoice[result._id] ? _c('span', [_vm._v("Recapturing...")]) : _vm._e(), !_vm.loading.recaptureInvoice[result._id] ? _c('span', [_c('i', {
      staticClass: "fa fa-sync mr-2"
    }), _vm._v("Recapture (Admin)")]) : _vm._e()]) : _vm._e(), _c('button', {
      staticClass: "btn btn-link text-gray-dark font-w600",
      attrs: {
        "disabled": _vm.loading.updateInvoice[result._id]
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickOnHold(result.invoice);
          }.apply(null, arguments);
        }
      }
    }, [_vm.loading.updateInvoice[result._id] ? _c('span', [_c('i', {
      staticClass: "fa fa-spinner fa-spin mr-1"
    }), _vm._v(" Updating...")]) : _vm._e(), !_vm.loading.updateInvoice[result._id] && result.invoice.uploaderStatus === 'onhold' ? _c('span', {
      attrs: {
        "title": "Set this invoice to Active"
      }
    }, [_c('i', {
      staticClass: "fa fa-play-circle mr-1"
    }), _vm._v("Activate")]) : _vm._e(), !_vm.loading.updateInvoice[result._id] && result.invoice.uploaderStatus !== 'onhold' ? _c('span', {
      attrs: {
        "title": "Set this invoice to On Hold"
      }
    }, [_c('i', {
      staticClass: "fa fa-stop-circle mr-1"
    }), _vm._v("Hold")]) : _vm._e()]), _c('button', {
      staticClass: "btn btn-link text-gray-dark font-w600",
      attrs: {
        "disabled": _vm.loading.updateInvoice[result._id]
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickTag(result.invoice);
          }.apply(null, arguments);
        }
      }
    }, [_vm.loading.updateInvoice[result._id] ? _c('span', [_c('i', {
      staticClass: "fa fa-spinner fa-spin mr-1"
    }), _vm._v(" Updating...")]) : _vm._e(), !_vm.loading.updateInvoice[result._id] ? _c('span', {
      attrs: {
        "title": "Set this invoice's tags"
      }
    }, [_c('i', {
      staticClass: "fa fa-tags mr-1"
    }), _vm._v("Tag")]) : _vm._e()])]), _c('div', {
      staticClass: "col-lg-auto text-center text-xl-right"
    }, [_c('InvoiceUploadStatus', {
      attrs: {
        "status": result.invoice.status,
        "error": result.invoice.error,
        "is-user": true
      }
    })], 1)]) : _vm._e()])])])]), _vm.loading.validateInvoice[result._id] ? _c('div', {
      staticClass: "collapse show"
    }, [_c('div', {
      staticClass: "block-content block-content-full"
    }, [_c('Spinner', [_vm._v("Validating... This may take a few seconds.")])], 1)]) : _c('div', {
      staticClass: "collapse",
      class: {
        show: result.validation.show
      }
    }, [result.validation.show ? _c('div', {
      staticClass: "block-content block-content-full"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-md-5"
    }, [!_vm.popup.includes(result._id) && result.invoice ? _c('InvoiceValidationPreview', {
      attrs: {
        "invoice": result.invoice
      },
      on: {
        "popup": function ($event) {
          return _vm.popup.push(result._id);
        }
      }
    }) : _c('button', {
      staticClass: "page-link",
      on: {
        "click": function ($event) {
          _vm.popup = _vm.popup.filter(function (id) {
            return result._id !== id;
          });
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-compress-arrows-alt"
    })])], 1), _c('div', {
      staticClass: "col-md-7",
      class: {
        'col-md-12': _vm.popup.includes(result._id)
      }
    }, [_vm.error.validateInvoice[result._id] ? _c('div', {
      staticClass: "alert alert-danger"
    }, [_c('i', {
      staticClass: "fa fa-triangle-exclamation mr-1"
    }), _vm._v(" There was an error validating this invoice. ")]) : _vm._e(), !result.validation.hasAccount ? _c('div', {
      staticClass: "block-content block-content-full"
    }, [_c('div', {
      staticClass: "alert alert-warning"
    }, [_vm._v("No account match")]), _c('p', [_vm._v(" Captur could not match this invoice to an account. This is likely because no account exists with the MPAN detected on the invoice. Please use the search below to locate the account. You can search by MPN/MPRN, Meter Serial Number, Name and Supplier Ref. ")]), _c('LookaheadSearch', {
      attrs: {
        "results": result.validation.accountSearch,
        "loading": _vm.loading.searchAccounts[result._id],
        "action": function (query, params) {
          return _vm.onSearchAccounts(result.invoice, query, params);
        },
        "title": "Account"
      },
      on: {
        "select": function (account) {
          return _vm.onSelectInvoiceAccount(result.invoice, account);
        }
      },
      scopedSlots: _vm._u([{
        key: "result",
        fn: function (slotProps) {
          return [_c('div', {
            staticClass: "d-flex justify-content-between align-items-center"
          }, [_c('div', [_c('span', {
            staticClass: "badge badge-success text-capitalize mr-2"
          }, [_vm._v(_vm._s(slotProps.data.supplier ? slotProps.data.supplier.name : 'N/A'))]), _c('span', {
            staticClass: "badge badge-info text-capitalize mr-2"
          }, [_vm._v(_vm._s(slotProps.data.type ? slotProps.data.type : 'N/A'))]), _vm._v(" " + _vm._s(slotProps.data.name) + " [" + _vm._s(slotProps.data.meterPointNumber) + "] ")]), _c('div', [_c('span', {
            staticClass: "badge badge-primary text-capitalize mr-2"
          }, [_vm._v(_vm._s(slotProps.data.asset.siteName))])])])];
        }
      }], null, true)
    })], 1) : _c('div', [_c('div', {
      staticClass: "d-flex justify-content-end mb-3"
    }, [_c('div', {
      staticClass: "btn btn-alt-danger mr-3",
      on: {
        "click": function ($event) {
          return _vm.onClickUnassociateAccount(result.invoice);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-times-circle"
    }), _vm._v(" Unmatch Account ")]), _vm.$auth.isAdmin ? _c('button', {
      staticClass: "btn btn-alt-info mr-3",
      attrs: {
        "disabled": _vm.loading.validateInvoice[result._id]
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickRevalidate(result.invoice);
          }.apply(null, arguments);
        }
      }
    }, [_vm.loading.validateInvoice[result._id] ? _c('span', [_c('i', {
      staticClass: "fa fa-fw fa-spin fa-redo"
    }), _vm._v(" Refreshing...")]) : _vm._e(), !_vm.loading.validateInvoice[result._id] ? _c('span', [_c('i', {
      staticClass: "fa fa-fw fa-redo"
    }), _vm._v(" Refresh")]) : _vm._e()]) : _vm._e(), (_result$invoice$accou = result.invoice.account) !== null && _result$invoice$accou !== void 0 && _result$invoice$accou.assetId && result.invoice.accountId ? _c('router-link', {
      staticClass: "btn btn-primary",
      attrs: {
        "to": {
          name: 'asset-accounts-overview',
          params: {
            id: result.invoice.account.assetId,
            accountId: result.invoice.accountId
          }
        },
        "target": "_blank"
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-arrow-right"
    }), _vm._v(" Go to Account")]) : _vm._e()], 1), _c('InvoiceValidationForm', {
      attrs: {
        "invoice": result.invoice
      },
      on: {
        "completed": function ($event) {
          return _vm.refreshInvoice(result._id);
        }
      }
    })], 1)])])]) : _vm._e()])]);
  }), _c('div', {
    staticClass: "text-center"
  }, [_vm.results.length !== _vm.invoicesTotal.filteredTotal && !_vm.loading.getInvoices ? _c('a', {
    staticClass: "btn btn-primary",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onShowMore.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-arrow-down mr-2"
  }), _vm._v(" Show more invoices"), _c('i', {
    staticClass: "fa fa-arrow-down ml-2"
  })]) : _vm._e()]), _vm.results.length !== _vm.invoicesTotal && _vm.loading.getInvoices ? _c('Spinner', {
    attrs: {
      "size": "sm"
    }
  }, [_vm._v("Loading more invoices...")]) : _vm._e()], 2) : _vm.loading.getInvoices ? _c('SpinnerLogo') : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" No invoices to be confirmed"), _vm.invoicesTotal.filteredTotal !== _vm.invoicesTotal.total ? _c('span', {
    staticClass: "font-w600"
  }, [_vm._v(" (There are filters applied)")]) : _vm._e()])]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.tag,
      "title": "Invoice Tags",
      "text": "Please select all relevant tags"
    },
    on: {
      "close": function ($event) {
        _vm.modals.tag = false;
      },
      "submit": _vm.onSubmitTags
    }
  }, [_vm.modals.tag ? _c('div', [_c('FormGroup', {
    attrs: {
      "id": "tags-modal",
      "options": _vm.invoiceCategories,
      "label": "Assigned Tags",
      "type": "select-array-list"
    },
    model: {
      value: _vm.modals.tag.tags,
      callback: function ($$v) {
        _vm.$set(_vm.modals.tag, "tags", $$v);
      },
      expression: "modals.tag.tags"
    }
  })], 1) : _vm._e()]), _c('ConfirmModal', {
    attrs: {
      "id": "assign-modal",
      "open": !!_vm.modals.assign,
      "title": "Assign Invoices",
      "lg-size": ""
    },
    on: {
      "close": function ($event) {
        _vm.modals.assign = false;
      },
      "submit": _vm.onAssignSubmit
    }
  }, [_vm.modals.assign ? _c('div', {
    attrs: {
      "data-cy": "assign-modal"
    }
  }, [_c('div', {
    staticClass: "list-group"
  }, [_c('div', {
    staticClass: "list-group-item",
    style: {
      border: 'none'
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-8 mt-2"
  }, [_vm._v("Please assign users to the invoices selected.")]), _c('div', {
    staticClass: "col-4"
  }, [_c('FormGroup', {
    attrs: {
      "id": "uploadedByTotal",
      "placeholder": "Assign All",
      "type": "select2",
      "config": {
        allowClear: true
      },
      "options": _vm.companyUsers
    },
    on: {
      "input": _vm.onAssignAll
    }
  })], 1)])])]), _c('div', {
    staticClass: "list-group mb-4"
  }, [_c('div', {
    staticClass: "list-group-item",
    style: {
      border: 'none'
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-8"
  }, [_c('label', {
    staticClass: "mb-0"
  }, [_vm._v("Invoice Name")])]), _c('div', {
    staticClass: "col-4"
  }, [_c('label', {
    staticClass: "mb-0"
  }, [_vm._v("User")])])])]), _vm._l(_vm.modals.assign, function (inv, index) {
    return _c('div', {
      key: index,
      staticClass: "list-group-item",
      style: {
        border: 'none'
      },
      attrs: {
        "href": "#"
      }
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-8 mt-2"
    }, [_vm._v(" " + _vm._s(inv.name) + " ")]), _c('div', {
      staticClass: "col-4"
    }, [_c('FormGroup', {
      staticClass: "mb-0",
      attrs: {
        "id": "uploadedBy",
        "placeholder": "Not assigned...",
        "type": "select2",
        "options": _vm.companyUsers
      },
      model: {
        value: inv.assignedTo,
        callback: function ($$v) {
          _vm.$set(inv, "assignedTo", $$v);
        },
        expression: "inv.assignedTo"
      }
    })], 1)])]);
  })], 2)]) : _vm._e()]), _c('ConfirmModal', {
    attrs: {
      "id": "delete-modal",
      "open": !!_vm.modals.delete.open,
      "title": "Delete Invoices",
      "lg-size": ""
    },
    on: {
      "close": _vm.onDeleteClosed,
      "submit": _vm.onDeleteSelected
    }
  }, [_vm.modals.delete ? _c('div', {
    attrs: {
      "data-cy": "delete-modal"
    }
  }, [_c('p', [_vm._v("Please confirm you would like to remove the following invoices:")]), _c('div', {
    staticClass: "list-group mb-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('label', {
    staticClass: "mb-0"
  }, [_vm._v("Invoice Name")])]), _c('div', {
    staticClass: "custom-control custom-checkbox custom-control-inline"
  }, [_c('label', {
    staticClass: "mb-0"
  }, [_vm._v("Scraper Off")])])]), _vm._l(_vm.toBeProcessedInvoices, function (inv) {
    return _c('div', {
      key: inv._id,
      staticClass: "list-group-item",
      attrs: {
        "href": "#"
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('div', [_vm._v(" " + _vm._s(inv.name) + " ")]), inv.scraped ? _c('div', {
      staticClass: "custom-control custom-checkbox custom-control-inline",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickNoScrape(inv.id);
        }
      }
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "id": inv.id,
        "name": inv.id,
        "type": "checkbox"
      },
      domProps: {
        "checked": _vm.modals.delete.noScrapeInvoices.some(function (id) {
          return id === inv.id;
        })
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": inv.id
      }
    })]) : _vm._e()])]);
  })], 2), _c('div', {
    staticClass: "custom-control custom-switch custom-control-lg text-right mr-4 mb-3"
  }, [_c('label', {
    staticClass: "mb-0 mr-3"
  }, [_vm._v("Select All")]), _c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "id": "selectAllNoScrape",
      "type": "checkbox",
      "name": "selectAllNoScrape",
      "disabled": _vm.invoices.filter(function (inv) {
        return _vm.selectedInvoices.some(function (id) {
          return inv._id === id;
        }) && inv.invoice.userSub.includes('-scraper');
      }).length === 0
    },
    domProps: {
      "checked": _vm.allNoScrapeInvoicesSelected
    },
    on: {
      "change": _vm.onSelectAllNoScrapeInvoices
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "selectAllNoScrape"
    }
  })])]) : _vm._e()])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text border-right-0 bg-white"
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mr-3 white-space"
  }, [_c('strong', [_vm._v("Sort By")])]);

}]

export { render, staticRenderFns }