var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading.initial ? _c('Spinner', [_vm._v("Loading preview...")]) : _c('div', {
    staticClass: "d-flex justify-content-between align-items-center px-4"
  }, [_c('ul', {
    staticClass: "pagination"
  }, [_c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.currentPage === 1
    }
  }, [_c('button', {
    staticClass: "page-link",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.currentPage -= 1;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-left"
  })])]), _c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.currentPage === _vm.pdfDocument.numPages
    }
  }, [_c('button', {
    staticClass: "page-link",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.currentPage += 1;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-right"
  })])]), _c('li', {
    staticClass: "page-item ml-3"
  }, [_c('button', {
    staticClass: "page-link",
    on: {
      "click": _vm.popup
    }
  }, [!_vm.loading.popup ? _c('i', {
    staticClass: "fas fa-external-link-alt"
  }) : _c('i', {
    staticClass: "fa fa-spin fa-circle-notch"
  })])]), _c('li', {
    staticClass: "page-item ml-3"
  }, [_c('button', {
    staticClass: "page-link",
    on: {
      "click": _vm.download
    }
  }, [!_vm.loading.download ? _c('i', {
    staticClass: "fas fa-download"
  }) : _c('i', {
    staticClass: "fa fa-spin fa-circle-notch"
  })])])]), _c('div', {
    staticClass: "font-w700"
  }, [_vm._v("Page " + _vm._s(_vm.currentPage) + " of " + _vm._s(_vm.pdfDocument.numPages))])]), _c('div', {
    staticClass: "pdf-preview mb-4"
  }, [_c('PdfViewer', {
    attrs: {
      "src": _vm.loading.pdf,
      "page": _vm.currentPage
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }