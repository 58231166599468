<template>
  <div data-cy="invoices-validation" class="pb-5">
    <div class="row items-push justify-content-between">
      <div class="col-sm-6 col-xl-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text border-right-0 bg-white">
              <i class="fa fa-search"></i>
            </span>
          </div>
          <input
            id="invoices-search"
            v-model="filters.search"
            type="text"
            class="form-control border-left-0 m-0"
            :style="{ height: 'auto' }"
            name="invoices-search"
            placeholder="Search Invoices..."
            @input="onSearch"
          />
          <div class="input-group-append">
            <select
              id="invoices-search-by"
              v-model="filters.searchBy"
              class="form-control form-control-alt"
              name="invoices-search-by"
              @input="onSearch"
            >
              <option value="fileName">Invoice File Name</option>
              <option value="values.meterPointNumber">MPRN/MPN/SPID</option>
              <option value="values.meterSerialNumber">Meter Serial Number</option>
              <option value="entity">Entity Name</option>
              <option value="asset">Asset Name</option>
              <option value="tags">Invoice Tag</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-xl-4 d-flex align-items-center">
        <div class="mr-3 white-space"><strong>Sort By</strong></div>
        <select id="invoices-sort" v-model="filters.sort" class="form-control" name="invoices-sort" @input="onSearch">
          <option value="createdAt:1">Capture Date (Asc)</option>
          <option value="createdAt:-1">Capture Date (Desc)</option>
          <option value="uploaderStatus:1">Uploader Status (Asc)</option>
          <option value="uploaderStatus:-1">Uploader Status (Desc)</option>
          <option value="values.invoiceDate:1">Invoice Date (Asc)</option>
          <option value="values.invoiceDate:-1">Invoice Date (Desc)</option>
          <option value="userSub:1">User (Asc)</option>
          <option value="userSub:-1">User (Desc)</option>
          <option value="fileName:1">File Name (Asc)</option>
          <option value="fileName:-1">File Name (Desc)</option>
        </select>
      </div>
    </div>
    <div class="row align-items-center bg-lighter pb-4 pt-3 px-3 rounded-md mb-3">
      <div class="col-md-1 pr-0">
        <label class="mb-2">Select All</label>
        <div class="custom-control custom-switch custom-control-lg">
          <input
            id="select-all-invoices"
            name="select-all-invoices"
            type="checkbox"
            class="custom-control-input"
            :checked="selectedInvoices.length !== 0 && selectedInvoices.length === results.length"
            @change="onSelectAllInvoices"
          />
          <label class="custom-control-label" for="select-all-invoices"></label>
        </div>
      </div>
      <div class="col-md-1">
        <FormGroup
          id="uploaderStatus"
          v-model="filters.uploaderStatus"
          class="mb-0"
          label="Status"
          type="select"
          :options="[
            { label: 'All', value: 'all' },
            { label: 'Active', value: 'active' },
            { label: 'On Hold', value: 'onhold' }
          ]"
          @input="onSearch"
        />
      </div>
      <div class="col-md-2">
        <FormGroup
          id="uploadedBy"
          v-model="filters.userSub"
          class="mb-0"
          label="Uploaded By"
          type="select"
          :options="uploadUsers"
          @input="onSearch"
        />
      </div>
      <div class="col-md-2">
        <FormGroup
          id="assignedTo"
          v-model="filters.assignedTo"
          class="mb-0"
          label="Assigned To"
          type="select"
          :options="assignedUsers"
          @input="onSearch"
        />
      </div>
      <div class="col-md-2">
        <FormGroup
          id="supplier"
          v-model="filters.supplierId"
          class="mb-0"
          label="Suppliers"
          type="select"
          :options="sortedSuppliers"
          @input="onSearch"
        />
      </div>
      <div class="col font-w600 text-right">
        <span v-if="invoicesTotal.filteredTotal !== invoicesTotal.total">
          Showing {{ results.length }} results out of {{ invoicesTotal.filteredTotal }} filtered results ({{ invoicesTotal.total }} total)</span
        >
        <span v-else>Showing {{ results.length }} results out of {{ invoicesTotal.filteredTotal }} total results</span>
      </div>
    </div>
    <div v-if="selectedInvoices.length > 0">
      <div class="d-flex align-items-center">
        <div class="custom-control custom-switch custom-control-lg">
          <button
            class="btn btn-alt-danger mr-3"
            :class="{ disabled: Object.keys(loading.deleteInvoices).some(key => loading.deleteInvoices[key]) }"
            @click="modals.delete.open = true"
          >
            <span v-if="Object.keys(loading.deleteInvoices).some(key => loading.deleteInvoices[key])"> Deleting... </span>
            <span v-else data-cy="delete-selected-btn"> Delete Selected </span>
          </button>
        </div>
        <div class="custom-control custom-switch custom-control-lg mr-5">
          <button class="btn btn-alt-warning" :class="{ disabled: loading.update }" @click="onAssign">
            <span data-cy="assign-selected-btn"> Assign </span>
          </button>
        </div>
      </div>
    </div>

    <div v-if="newInvoices > 0" class="alert alert-info">
      {{ newInvoices }} new invoice{{ newInvoices > 1 ? 's have' : ' has' }} been uploaded.
      <a href="#" class="font-w600" @click.prevent="refresh">Click here to refresh</a>
    </div>

    <div v-if="results.length > 0">
      <div v-for="result in results" :key="result._id" class="InvoiceValidation-item">
        <div
          class="block-content block-content-full ribbon ribbon-danger ribbon-left ribbon-modern"
          :class="{ 'border-bottom': result.validation.show }"
        >
          <div v-if="result.invoice.uploaderStatus === 'onhold'" class="ribbon-box ribbon-box-sm text-uppercase">On Hold</div>
          <div class="row justify-content-between align-items-center">
            <div class="col-lg mb-4 mb-xl-0">
              <div class="row">
                <div class="col-xl-6 d-flex align-items-center mb-4 mb-xl-0">
                  <div class="custom-control custom-checkbox custom-control-lg ml-3 mr-4">
                    <input
                      :id="result._id"
                      type="checkbox"
                      class="custom-control-input"
                      :name="result._id"
                      :checked="selectedInvoices.some(id => id === result._id)"
                      @change="onSelectInvoice(result.invoice)"
                    />
                    <label class="custom-control-label" :for="result._id"></label>
                  </div>
                  <div v-if="!loading.updateInvoice[result._id] && !loading.getInvoice[result._id]" class="ml-3">
                    <a href="#" @click.prevent="onClickShowInvoice(result)">
                      <div class="mr-2">
                        <strong><InvoiceName :invoice="result.invoice" /></strong>
                      </div>
                      <small
                        >Uploaded by <strong>{{ getUserFromSub(result.invoice.userSub) }}</strong> at
                        <strong>{{ result.invoice.createdAt | date('Do MMM YYYY HH:mm:ss') }}</strong></small
                      >
                      <div>
                        <span v-if="result.invoice.supplier && result.invoice.supplier.name" class="badge badge-success text-capitalize mr-2">{{
                          result.invoice.supplier.name
                        }}</span>
                        <span v-if="result.invoice.type" class="badge badge-info text-capitalize mr-2">{{ result.invoice.type }}</span>
                        <span v-if="result.invoice.accountId" class="badge badge-warning mr-2">Account Match</span>
                        <span v-if="result.invoice.templateVersion" class="badge badge-secondary mr-2">{{ result.invoice.templateVersion }}</span>

                        <span
                          v-if="
                            result.validation.hasAccount &&
                            result.validation.results.messages &&
                            result.validation.results.messages.length > 0 &&
                            result.validation.results.messages.every(r => r.type === 'success')
                          "
                          class="mr-2"
                          ><i class="fa fa-check-circle text-success"></i
                        ></span>
                        <span
                          v-for="tag in result.invoice.tags"
                          :key="tag"
                          class="badge badge-primary text-capitalize mr-2"
                          @click.stop.prevent="onClickTagFilter(tag)"
                          ><i class="fa fa-fw fa-tag"></i> {{ tag }}</span
                        >
                        <span v-if="result.invoice.assignedTo" class="badge badge-primary text-capitalize mr-2"
                          ><i class="fa fa-fw fa-user"></i> {{ getUserFromSub(result.invoice.assignedTo) }}</span
                        >
                      </div>
                    </a>
                  </div>
                  <span v-else class="font-w600 text-secondary mr-2 my-4"> <i class="fa fa-spinner fa-spin mr-1" /> Loading... </span>
                </div>
                <div
                  v-if="!loading.updateInvoice[result._id] && !loading.getInvoice[result._id]"
                  class="col-xl-6 d-flex justify-content-xl-end align-items-center"
                >
                  <div v-if="loading.deleteInvoices[result._id]" class="font-w600 text-danger mr-2">
                    <i class="fa fa-spinner fa-spin mr-1" /> Deleting...
                  </div>
                  <div v-else>
                    <button
                      v-if="$auth.isAdmin"
                      class="btn btn-link font-w600 text-info"
                      :disabled="loading.recaptureInvoice[result._id]"
                      @click.prevent="() => onClickRecapture(result.invoice)"
                    >
                      <span v-if="loading.recaptureInvoice[result._id]">Recapturing...</span>
                      <span v-if="!loading.recaptureInvoice[result._id]"><i class="fa fa-sync mr-2"></i>Recapture (Admin)</span>
                    </button>
                    <button
                      class="btn btn-link text-gray-dark font-w600"
                      :disabled="loading.updateInvoice[result._id]"
                      @click.prevent="() => onClickOnHold(result.invoice)"
                    >
                      <span v-if="loading.updateInvoice[result._id]"><i class="fa fa-spinner fa-spin mr-1" /> Updating...</span>
                      <span v-if="!loading.updateInvoice[result._id] && result.invoice.uploaderStatus === 'onhold'" title="Set this invoice to Active"
                        ><i class="fa fa-play-circle mr-1"></i>Activate</span
                      >
                      <span
                        v-if="!loading.updateInvoice[result._id] && result.invoice.uploaderStatus !== 'onhold'"
                        title="Set this invoice to On Hold"
                        ><i class="fa fa-stop-circle mr-1"></i>Hold</span
                      >
                    </button>
                    <button
                      class="btn btn-link text-gray-dark font-w600"
                      :disabled="loading.updateInvoice[result._id]"
                      @click.prevent="() => onClickTag(result.invoice)"
                    >
                      <span v-if="loading.updateInvoice[result._id]"><i class="fa fa-spinner fa-spin mr-1" /> Updating...</span>
                      <span v-if="!loading.updateInvoice[result._id]" title="Set this invoice's tags"><i class="fa fa-tags mr-1"></i>Tag</span>
                    </button>
                  </div>
                  <div class="col-lg-auto text-center text-xl-right">
                    <InvoiceUploadStatus :status="result.invoice.status" :error="result.invoice.error" :is-user="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading.validateInvoice[result._id]" class="collapse show">
          <div class="block-content block-content-full">
            <Spinner>Validating... This may take a few seconds.</Spinner>
          </div>
        </div>

        <div v-else class="collapse" :class="{ show: result.validation.show }">
          <div v-if="result.validation.show" class="block-content block-content-full">
            <div class="row">
              <div class="col-md-5">
                <InvoiceValidationPreview
                  v-if="!popup.includes(result._id) && result.invoice"
                  :invoice="result.invoice"
                  @popup="popup.push(result._id)"
                />
                <button v-else class="page-link" @click="popup = popup.filter(id => result._id !== id)">
                  <i class="fas fa-compress-arrows-alt"></i>
                </button>
              </div>
              <div class="col-md-7" :class="{ 'col-md-12': popup.includes(result._id) }">
                <div v-if="error.validateInvoice[result._id]" class="alert alert-danger">
                  <i class="fa fa-triangle-exclamation mr-1"></i> There was an error validating this invoice.
                </div>
                <div v-if="!result.validation.hasAccount" class="block-content block-content-full">
                  <div class="alert alert-warning">No account match</div>
                  <p>
                    Captur could not match this invoice to an account. This is likely because no account exists with the MPAN detected on the invoice.
                    Please use the search below to locate the account. You can search by MPN/MPRN, Meter Serial Number, Name and Supplier Ref.
                  </p>
                  <LookaheadSearch
                    :results="result.validation.accountSearch"
                    :loading="loading.searchAccounts[result._id]"
                    :action="(query, params) => onSearchAccounts(result.invoice, query, params)"
                    title="Account"
                    @select="account => onSelectInvoiceAccount(result.invoice, account)"
                  >
                    <template v-slot:result="slotProps">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <span class="badge badge-success text-capitalize mr-2">{{
                            slotProps.data.supplier ? slotProps.data.supplier.name : 'N/A'
                          }}</span>
                          <span class="badge badge-info text-capitalize mr-2">{{ slotProps.data.type ? slotProps.data.type : 'N/A' }}</span>
                          {{ slotProps.data.name }} [{{ slotProps.data.meterPointNumber }}]
                        </div>
                        <div>
                          <span class="badge badge-primary text-capitalize mr-2">{{ slotProps.data.asset.siteName }}</span>
                        </div>
                      </div>
                    </template>
                  </LookaheadSearch>
                </div>
                <div v-else>
                  <div class="d-flex justify-content-end mb-3">
                    <div class="btn btn-alt-danger mr-3" @click="onClickUnassociateAccount(result.invoice)">
                      <i class="fa fa-fw fa-times-circle"></i> Unmatch Account
                    </div>

                    <button
                      v-if="$auth.isAdmin"
                      class="btn btn-alt-info mr-3"
                      :disabled="loading.validateInvoice[result._id]"
                      @click.prevent="() => onClickRevalidate(result.invoice)"
                    >
                      <span v-if="loading.validateInvoice[result._id]"><i class="fa fa-fw fa-spin fa-redo"></i> Refreshing...</span>
                      <span v-if="!loading.validateInvoice[result._id]"><i class="fa fa-fw fa-redo"></i> Refresh</span>
                    </button>

                    <router-link
                      v-if="result.invoice.account?.assetId && result.invoice.accountId"
                      :to="{ name: 'asset-accounts-overview', params: { id: result.invoice.account.assetId, accountId: result.invoice.accountId } }"
                      class="btn btn-primary"
                      target="_blank"
                      ><i class="fa fa-fw fa-arrow-right"></i> Go to Account</router-link
                    >
                  </div>
                  <InvoiceValidationForm :invoice="result.invoice" @completed="refreshInvoice(result._id)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a v-if="results.length !== invoicesTotal.filteredTotal && !loading.getInvoices" class="btn btn-primary" href="#" @click.prevent="onShowMore"
          ><i class="fa fa-arrow-down mr-2"></i> Show more invoices<i class="fa fa-arrow-down ml-2"></i>
        </a>
      </div>
      <Spinner v-if="results.length !== invoicesTotal && loading.getInvoices" size="sm">Loading more invoices...</Spinner>
    </div>
    <SpinnerLogo v-else-if="loading.getInvoices" />
    <div v-else class="alert alert-warning" role="alert">
      <p class="mb-0">
        No invoices to be confirmed<span v-if="invoicesTotal.filteredTotal !== invoicesTotal.total" class="font-w600">
          (There are filters applied)</span
        >
      </p>
    </div>

    <ConfirmModal :open="!!modals.tag" title="Invoice Tags" text="Please select all relevant tags" @close="modals.tag = false" @submit="onSubmitTags">
      <div v-if="modals.tag">
        <FormGroup id="tags-modal" v-model="modals.tag.tags" :options="invoiceCategories" label="Assigned Tags" type="select-array-list" />
      </div>
    </ConfirmModal>
    <ConfirmModal id="assign-modal" :open="!!modals.assign" title="Assign Invoices" lg-size @close="modals.assign = false" @submit="onAssignSubmit">
      <div v-if="modals.assign" data-cy="assign-modal">
        <div class="list-group">
          <div class="list-group-item" :style="{ border: 'none' }">
            <div class="row">
              <div class="col-8 mt-2">Please assign users to the invoices selected.</div>
              <div class="col-4">
                <FormGroup
                  id="uploadedByTotal"
                  placeholder="Assign All"
                  type="select2"
                  :config="{ allowClear: true }"
                  :options="companyUsers"
                  @input="onAssignAll"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="list-group mb-4">
          <div class="list-group-item" :style="{ border: 'none' }">
            <div class="row">
              <div class="col-8"><label class="mb-0">Invoice Name</label></div>
              <div class="col-4"><label class="mb-0">User</label></div>
            </div>
          </div>
          <div v-for="(inv, index) in modals.assign" :key="index" href="#" class="list-group-item" :style="{ border: 'none' }">
            <div class="row">
              <div class="col-8 mt-2">
                {{ inv.name }}
              </div>
              <div class="col-4">
                <FormGroup
                  id="uploadedBy"
                  v-model="inv.assignedTo"
                  class="mb-0"
                  placeholder="Not assigned..."
                  type="select2"
                  :options="companyUsers"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfirmModal>
    <ConfirmModal id="delete-modal" :open="!!modals.delete.open" title="Delete Invoices" lg-size @close="onDeleteClosed" @submit="onDeleteSelected">
      <div v-if="modals.delete" data-cy="delete-modal">
        <p>Please confirm you would like to remove the following invoices:</p>
        <div class="list-group mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <div><label class="mb-0">Invoice Name</label></div>
            <div class="custom-control custom-checkbox custom-control-inline"><label class="mb-0">Scraper Off</label></div>
          </div>
          <div v-for="inv in toBeProcessedInvoices" :key="inv._id" href="#" class="list-group-item">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                {{ inv.name }}
              </div>
              <div v-if="inv.scraped" class="custom-control custom-checkbox custom-control-inline" @click.prevent="onClickNoScrape(inv.id)">
                <input
                  :id="inv.id"
                  :name="inv.id"
                  type="checkbox"
                  class="custom-control-input"
                  :checked="modals.delete.noScrapeInvoices.some(id => id === inv.id)"
                />
                <label class="custom-control-label" :for="inv.id"> </label>
              </div>
            </div>
          </div>
        </div>

        <div class="custom-control custom-switch custom-control-lg text-right mr-4 mb-3">
          <label class="mb-0 mr-3">Select All</label>
          <input
            id="selectAllNoScrape"
            type="checkbox"
            class="custom-control-input"
            name="selectAllNoScrape"
            :checked="allNoScrapeInvoicesSelected"
            :disabled="invoices.filter(inv => selectedInvoices.some(id => inv._id === id) && inv.invoice.userSub.includes('-scraper')).length === 0"
            @change="onSelectAllNoScrapeInvoices"
          />
          <label class="custom-control-label" for="selectAllNoScrape"></label>
        </div>
      </div>
    </ConfirmModal>
  </div>
</template>
<script>
import bluebird from 'bluebird';
import { debounce } from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ConfirmModal from './ConfirmModal';
import FormGroup from './FormGroup';
import InvoiceName from './InvoiceName';
import InvoiceValidationForm from './InvoiceValidationForm';
import InvoiceValidationPreview from './InvoiceValidationPreview';
import InvoiceUploadStatus from './InvoiceUploadStatus';
import LookaheadSearch from './LookaheadSearch';
import Spinner from './Spinner';
import SpinnerLogo from './SpinnerLogo';

import { capitalize, friendlyInvoiceName } from '../lib/helpers';

export default {
  name: 'InvoiceValidation',
  components: {
    ConfirmModal,
    FormGroup,
    InvoiceName,
    InvoiceValidationForm,
    InvoiceValidationPreview,
    InvoiceUploadStatus,
    LookaheadSearch,
    Spinner,
    SpinnerLogo
  },
  data() {
    return {
      filters: {
        sort: 'createdAt:-1',
        searchBy: 'entity',
        search: null,
        uploaderStatus: 'all',
        userSub: 'all',
        supplierId: 'all',
        assignedTo: 'all'
      },
      showValidation: {},
      loadingAccounts: {},
      modals: {
        tag: false,
        delete: {
          open: false,
          noScrapeInvoices: []
        },
        assign: false
      },
      popup: [],
      perPage: 100,
      additionalPages: 0
    };
  },
  computed: {
    ...mapGetters({
      invoices: 'invoiceValidation/invoices',
      loading: 'invoiceValidation/loading',
      error: 'invoiceValidation/error',
      selectedInvoices: 'invoiceValidation/selectedInvoices',
      users: 'company/users',
      company: 'user/company',
      suppliers: 'supplier/suppliers',
      invoicesTotal: 'invoiceValidation/invoicesTotal',
      newInvoices: 'invoiceValidation/newInvoices',
      stats: 'invoiceValidation/stats'
    }),
    assignedUsers() {
      const items = (this.stats.assignedUsers || []).map(item => ({ label: `${item.name} (${item.count})`, value: item._id }));

      items.sort((a, b) => a.label.localeCompare(b.label));

      return [{ label: 'All', value: 'all' }, ...items];
    },
    uploadUsers() {
      const items = (this.stats.users || []).map(item => ({ label: `${item.name} (${item.count})`, value: item._id }));

      items.sort((a, b) => a.label.localeCompare(b.label));

      return [{ label: 'All', value: 'all' }, ...items];
    },
    sortedSuppliers() {
      const items = (this.stats.suppliers || []).map(item => ({ label: `${item.name} (${item.count})`, value: item._id }));

      items.sort((a, b) => a.label.localeCompare(b.label));

      return [{ label: 'All', value: 'all' }, ...items];
    },
    sortedEntities() {
      const items = (this.stats.entities || []).map(item => ({ label: `${item.name} (${item.count})`, value: item._id }));

      items.sort((a, b) => a.label.localeCompare(b.label));

      return [{ label: 'All', value: 'all' }, ...items];
    },
    allNoScrapeInvoicesSelected() {
      if (this.invoices.filter(inv => this.selectedInvoices.some(id => inv._id === id) && inv.invoice.userSub.includes('-scraper')).length === 0)
        return false;
      return (
        this.modals.delete.noScrapeInvoices.length ===
        this.invoices.filter(inv => this.selectedInvoices.some(id => inv._id === id) && inv.invoice.userSub.includes('-scraper')).length
      );
    },
    toBeProcessedInvoices() {
      return this.invoices
        .filter(inv => this.selectedInvoices.some(id => inv._id === id))
        .map(inv => {
          return { name: friendlyInvoiceName(inv.invoice), id: inv._id, scraped: inv.invoice.userSub.includes('-scraper') };
        });
    },
    results() {
      return this.invoices.filter(invoice => invoice.invoice.status !== 'completed').map(invoice => ({ ...invoice, showForm: false }));
    },
    invoiceCategories() {
      if (!this.company || !this.company.settings) return [];
      const sortedInvoiceCategories = this.company.settings.invoiceCategories
        ? this.company.settings.invoiceCategories.map(category => ({ label: category, value: category }))
        : [];
      return sortedInvoiceCategories.sort((a, b) => a.label.localeCompare(b.label));
    },
    companyUsers() {
      const users = this.users.filter(u => !u.entityId).map(u => ({ label: u.name, value: u.userSub }));

      users.sort((a, b) => a.label.localeCompare(b.label));

      return users;
    }
  },
  async created() {
    await this.refresh();
    await this.getCompany();
    this.getInvoiceKeys();
    this.listUsers({ id: this.$auth.companyId });
    this.listSuppliers({ data: { params: { type: 'supplier' } } });
    this.getStats();
    this.listUnits();
  },
  methods: {
    ...mapActions({
      getInvoices: 'invoiceValidation/getInvoices',
      getMoreInvoices: 'invoiceValidation/getMoreInvoices',
      getInvoice: 'invoiceValidation/getInvoice',
      updateInvoice: 'invoiceValidation/updateInvoice',
      deleteInvoices: 'invoiceValidation/deleteInvoices',
      getInvoiceKeys: 'invoiceValidation/getInvoiceKeys',
      validateInvoice: 'invoiceValidation/validateInvoice',
      recaptureInvoice: 'invoiceValidation/recaptureInvoice',
      downloadInvoice: 'invoice/download',
      searchAccounts: 'invoiceValidation/searchAccounts',
      getStats: 'invoiceValidation/getStats',
      listUsers: 'company/listUsers',
      getCompany: 'user/getCompany',
      listEntities: 'entity/list',
      listAccounts: 'account/list',
      listSuppliers: 'supplier/list',
      listUnits: 'util/listUnits'
    }),
    ...mapMutations({
      selectInvoice: 'invoiceValidation/SELECT_INVOICE',
      selectAllInvoices: 'invoiceValidation/SELECT_ALL_INVOICES',
      unselectAllInvoices: 'invoiceValidation/UNSELECT_ALL_INVOICES',
      toggleValidationResults: 'invoiceValidation/TOGGLE_DISPLAY_VALIDATION_RESULTS',
      resetInvoiceResults: 'invoiceValidation/VALIDATE_INVOICE_RESET'
    }),
    onShowMore() {
      this.additionalPages++;
      this.refresh(false);
    },
    onAssignAll(assignTo) {
      this.modals.assign = this.modals.assign.map(inv => ({
        id: inv.id,
        assignedTo: assignTo,
        name: inv.name
      }));
    },
    onDeleteClosed() {
      this.modals.delete = {
        open: false,
        noScrapeInvoices: []
      };
    },
    onClickNoScrape(invoiceId) {
      this.modals.delete.noScrapeInvoices = this.modals.delete.noScrapeInvoices.some(id => id === invoiceId)
        ? this.modals.delete.noScrapeInvoices.filter(id => id !== invoiceId)
        : [...this.modals.delete.noScrapeInvoices, invoiceId];
    },
    onAssign() {
      this.modals.assign = this.invoices
        .filter(inv => this.selectedInvoices.some(id => inv._id === id))
        .map(inv => ({
          id: inv.id,
          assignedTo: inv.invoice.assignedTo,
          name: friendlyInvoiceName(inv.invoice)
        }));
    },
    async onClickRecapture(invoice) {
      await this.recaptureInvoice({ id: invoice._id });
      await this.refreshInvoice(invoice._id);
    },
    async onDeleteSelected() {
      await this.deleteInvoices({ ids: this.selectedInvoices, noScrapeIds: this.modals.delete.noScrapeInvoices });
      this.$toasted.success('Invoices deleted successfully');
      this.unselectAllInvoices();
    },
    async onAssignSubmit() {
      const invoicesToBeUpdated = [...this.modals.assign].filter(
        inv => inv.assignedTo !== this.invoices.find(i => i.id === inv.id).invoice.assignedTo
      );
      if (invoicesToBeUpdated.length !== 0) {
        await bluebird.map(invoicesToBeUpdated, async inv => this.updateInvoice({ id: inv.id, data: { assignedTo: inv.assignedTo } }), {
          concurrency: 5
        });
      }
      this.$toasted.success('Invoices updated successfully');
      this.unselectAllInvoices();

      invoicesToBeUpdated.forEach(inv => {
        this.$mixpanel.track('Assign Invoice', {
          'Invoice ID': inv.id,
          'Assign User': inv.assignedTo
        });
      });
    },
    async onClickShowInvoice(invoice) {
      if (!invoice.validation.show && invoice.invoice.accountId) {
        await this.validateInvoice({ id: invoice._id });
      }

      this.toggleValidationResults(invoice._id);
      this.popup = this.popup.filter(invId => invId !== invoice._id);
    },
    async onClickOnHold(invoice) {
      if (invoice.uploaderStatus === 'onhold') {
        await this.updateInvoice({
          id: invoice._id,
          data: { uploaderStatus: 'active' }
        });
      } else {
        await this.updateInvoice({
          id: invoice._id,
          data: { uploaderStatus: 'onhold' }
        });
      }

      await this.refreshInvoice(invoice._id);
    },
    async onClickTag(invoice) {
      this.modals.tag = {
        tags: invoice.tags || [],
        invoiceId: invoice._id
      };
    },
    async onClickTagFilter(tag) {
      this.filters.searchBy = 'tags';
      this.filters.search = tag;

      this.refresh();
    },
    async onSubmitTags() {
      const id = this.modals.tag.invoiceId;
      const tags = this.modals.tag.tags;

      this.modals.tag = false;

      await this.updateInvoice({ id, data: { tags } });

      await this.refreshInvoice(id);
    },
    async onClickRevalidate(invoice) {
      await this.validateInvoice({ id: invoice._id, isManual: true });
    },
    onSelectAllInvoices(e) {
      if (e.target.checked) {
        this.selectAllInvoices();
      } else {
        this.unselectAllInvoices();
      }
    },
    onSelectAllNoScrapeInvoices(e) {
      e.target.checked
        ? (this.modals.delete.noScrapeInvoices = this.invoices
            .filter(inv => this.selectedInvoices.some(id => inv._id === id) && inv.invoice.userSub.includes('-scraper'))
            .map(inv => inv.id))
        : (this.modals.delete.noScrapeInvoices = []);
    },
    onSelectInvoice(invoice) {
      this.selectInvoice(invoice);
    },
    async onSelectInvoiceAccount(invoice, account) {
      await this.updateInvoice({
        id: invoice._id,
        data: {
          accountId: account._id,
          type: account.type,
          supplierId: account.supplierId
        }
      });

      await this.refreshInvoice(invoice._id);
      this.validateInvoice({ id: invoice._id, isManual: true });
    },
    onSearch: debounce(function () {
      this.refresh();
    }, 300),
    async refresh(fullReset = true) {
      if (fullReset) this.additionalPages = 0;

      const filters = {
        ...this.filters,
        assignedTo: this.filters.assignedTo || 'null',
        supplierId: this.filters.supplierId || 'null'
      };

      this.getInvoices({
        skip: fullReset ? 0 : this.additionalPages * this.perPage,
        limit: this.perPage,
        filters,
        fullReset
      });
    },
    async refreshInvoice(id) {
      await this.getInvoice({
        id,
        params: {
          $populate: 'account,account.asset,account.entity'
        }
      });
    },
    entityFetch(accountId) {
      const acc = this.accounts.find(a => a._id === accountId);
      return this.entities.find(e => acc.entityId === e._id);
    },
    getUserFromSub(sub) {
      if (sub === 'captur-email-receiver') return 'Email';
      if (sub.includes('portal-scraper')) {
        const scraperId = sub.split('-')[2];
        return `Scraper (${capitalize(scraperId)})`;
      }

      if (sub.includes('auth0|')) {
        const user = (this.users || []).find(user => user.userSub === sub);

        return user ? user.name : 'N/A';
      } else {
        return sub;
      }
    },
    async onSearchAccounts(invoice, query, params) {
      const accountSearchParams = {
        ...params
      };

      if (invoice.type) {
        accountSearchParams.type = invoice.type;
      }

      if (invoice.supplierId) {
        accountSearchParams.supplierId = invoice.supplierId;
      }

      return this.searchAccounts({
        invoiceId: invoice._id,
        query,
        params: { companyId: invoice.companyId, parentAccountId: 'null', ...accountSearchParams }
      });
    },
    async onClickUnassociateAccount(invoice) {
      await this.updateInvoice({ id: invoice._id, data: { accountId: null, type: null, supplierId: null } });
      await this.refreshInvoice(invoice._id);
      this.resetInvoiceResults(invoice._id);
    }
  }
};
</script>
<style lang="scss" scoped>
.results-progress {
  width: 180px;
}

.is-changed {
  color: #da4c4c !important;
  text-decoration: line-through;
  font-weight: 600;
}

.white-space {
  white-space: nowrap;
}

.InvoiceValidation-item {
  border-top: 1px solid #e9e9e9;
}

.InvoiceValidation-item:first-child {
  border: none;
}
</style>
